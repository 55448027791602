import React from "react"
import Seo from "../core/Seo"
import SupportLayout from "../support/Page"
import { ThemeProvider } from "styled-components"

const SupportPage = () => {
  return (
    <ThemeProvider theme={{ colors: { accent: "#3790E2" } }}>
        <Seo title="We Sparkle • Simple Tools for Solopreneurs" includeZohoTracking />
        <SupportLayout />
    </ThemeProvider>
  )
}

export default SupportPage