import React from "react"
import styled from "styled-components"
import { ButtonBase } from "../homepage/Buttons"
import { segmentAction } from "../hooks/useSegmentTrack"

const Border = styled.div`
  border: 2px solid ${({ color }) => color};
  transition: 0.3s;
  opacity: 0.5;
  border-radius: 20px;
  position: absolute;
  left: 0;
  top: 0;
`
const OptionHeader = styled.h2`
  color: ${({ color }) => color};
`
const OptionDescription = styled.p`
  margin: 1.5rem 0;
  text-align: left;
  font-size: 1rem;
`
const Link = styled(ButtonBase)`
  position: relative;
  transform: translateZ(0);
  width: 100%;
  opacity: 1;
  color: var(--sparkle-drkblue) !important;
  padding: 0.7em 0.5em;
  font-weight: bold;
  font-size: 1rem;
  background: ${({ bg }) => bg};
  letter-spacing: 0.02em;
  text-transform: uppercase;
  border-radius: 100px;
  margin: 1.5rem 0rem 0rem;
  transition: 0.15s;
`

const Container = styled.div`
  margin: 4rem 0 6rem;
  display: flex;
  gap: 2rem;

  @media screen and (max-width: 900px) {
    flex-wrap: wrap;
    gap: 1rem;
    margin-bottom: 3rem;
  }
`
const Option = styled.article`
  position: relative;
  text-align: center;
  padding: 2rem 1.5rem;
  flex: 300px;
  cursor: pointer;
  transform: translateZ(0);
  transition: 0.2s;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  :hover {
    transform: translateY(-5px);
    ${Link} {
      opacity: 1;
      transform: scale(1.01) translateY(-1px) translateZ(0);
      box-shadow: 0 10px 15px rgba(0, 0, 0, 0.5);
      span {
        transform: translateY(-50%);
        opacity: 1;
      }
    }
    ${Border} {
      opacity: 1;
    }
  }
  @media screen and (max-width: 900px) {
    padding: 1.5rem;
  }
`

const options = [
  {
    accentColor: "var(--sparkle-yellow)",
    name: "General Questions",
    description: [
      "Questions? Interested in an enterprise customization? Drop us a line!",
      <br />,
      <br />,
      "You can also type your question in the chat for our Sparkle Assistant to answer.",
    ],
    cta: "Contact Us",
    ctaUrl: "mailto:support@wesparkle.ai",
  },
  {
    accentColor: "var(--sparkle-green)",
    name: "Technical Support",
    description: [
      "Need help? Questions about your Sparkle Site?",
      <br />,
      <br />,
      "Our team is here for you.",
    ],
    cta: "Visit Help Site",
    ctaUrl: "https://community.sprkl.es/portal/en/kb",
  },
  {
    accentColor: "var(--sparkle-brightblue)",
    name: "Schedule a Demo",
    description:
      "Join us for our monthly office hours. Get a demo, bring your tech questions (We Sparkle or otherwise), get help setting up your account, or just say hi (we're nice!).",
    cta: "Schedule a Demo",
    ctaUrl:
      "https://sprkl.es/melissa/?project=5e30d961-ff4e-445c-bf4a-a4dbbab38f85#bookings",
  },
]

const PageContact = () => {
  return (
    <div>
      <h1>Let's Connect!</h1>
      <Container>
        {options.map(
          ({ accentColor: color, name, description, cta, ctaUrl }, i) => {
            return (
              <Option color={color} key={i}>
                <Border color={color} className={"pos-full"} />
                <div>
                  <OptionHeader color={color}>{name}</OptionHeader>
                  <OptionDescription>{description}</OptionDescription>
                </div>
                <Link
                  as={"a"}
                  href={ctaUrl}
                  target={"_blank"}
                  rel={"noopener noreferrer"}
                  bg={color}
                  onClick={() => {
                    segmentAction.track(`Support Page Contact Click`, {
                      value: name,
                    })
                  }}
                >
                  {cta}
                </Link>
              </Option>
            )
          }
        )}
      </Container>
    </div>
  )
}

export default PageContact
