import React from "react"
import styled, { ThemeProvider } from "styled-components"

import { TenantSignedInProvider } from "../hooks/useTenantSignedInContext"
import { TenantInfoProvider } from "../hooks/useTenantInfoContext"
import { SignUpProvider } from "../homepage/useSignUpContext"

import Faq from "../core/FAQs"
import GlobalStyles from "../homepage/GlobalStyles"
import "../homepage/layout.css"
import PageContact from "../homepage/PageContact"
import PageNav from "../homepage/PageNav"
import PageSupport from "./PageSupport"

const Section = styled.section`
  :before,
  :after {
    content: "";
    clear: both;
    display: table;
  }
  line-height: 1.3;
  font-size: 1.25rem;
  h1,
  h2,
  h3,
  h4,
  h5 {
    font-weight: bold;
  }
  h1 {
    font-size: 4.5rem;
    line-height: 0.95;
    margin-bottom: 0.5em;
  }
  @media screen and (max-width: 900px) {
    font-size: 1rem;
    line-height: 1.4;
    h1 {
      font-size: 2.25rem;
      line-height: 0.95;
      margin-bottom: 0.5em;
    }
  }
  ${({ dark }) => {
    if (dark)
      return `
        background: var(--sparkle-drkblue);
        color: #fff;
    `
  }}
`
const Content = styled.div`
  padding: 8rem var(--content-pad);
  max-width: 900px;
  margin: auto;
  @media screen and (max-width: 900px) {
    padding: 3rem var(--content-pad);
  }
`
const faqs = [
  {
    question: "What's the easiest way to get in contact with your team?",
    answer:
      "Our Sparkle Assistant is available at any time to answer general questions, provide relevant help articles, or start a support ticket. Click on the chat icon in the lower right corner and type your question.",
  },
  {
    question: "Where can I view the specific features of each plan?",
    answer: [
      "Visit our ",
      <a href="/pricing">Pricing Page</a>,
      " for a detailed breakdown of what each of our plans offers. If you're curious about anything else, we're happy to ",
      <a
        href="https://sprkl.es/melissa/?project=5e30d961-ff4e-445c-bf4a-a4dbbab38f85#bookings"
        target="_blank"
        rel="noopener noreferrer"
      >
        schedule a one-on-one demo
      </a>,
      " with you to answer any and all of your questions.",
    ],
  },
  {
    question: "My Sparkle Site isn't working, help!",
    answer: [
      "No worries, we're here for you! Our ",
      <a
        href="https://community.sprkl.es/portal/en/home/"
        target="_blank"
        rel="noopener noreferrer"
      >
        help site
      </a>,
      " is the best place to learn about troubleshooting and best practices",
    ],
  },
]

const Page = () => {
  return (
    <ThemeProvider theme={{ color: { accent: "#3790E2" } }}>
      <TenantInfoProvider>
        <TenantSignedInProvider>
          <SignUpProvider>
            <GlobalStyles />
            <PageNav
              links={[
                { name: "Features", href: "/pricing#features" },
                { name: "Pricing", href: "/pricing#top" },
                { name: "Our Story", href: "/about" },
                { name: "Contact", href: "/contact" },
              ]}
            />
            <Section dark>
              <Content>
                <PageSupport />
                <Faq faqs={faqs} />
              </Content>
            </Section>
            <Section dark id={"follow-for-more"} as={"footer"}>
              <Content style={{ padding: "var(--content-pad)" }}>
                <PageContact />
              </Content>
            </Section>
          </SignUpProvider>
        </TenantSignedInProvider>
      </TenantInfoProvider>
    </ThemeProvider>
  )
}

export default Page
